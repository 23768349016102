<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModal">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <div class="modal-header" id="modalTitle">
          <div class="header">
            Ваш QR-код для входа на мероприятие
          </div>
        </div>
        <div class="modal-body" id="modalDescription">
          <div class="body">
            <div v-if="user && event">
              <p>Вы авторизованы как: {{ user.surname }} {{ user.name }} {{ user.thirdname }}</p>
              <p>Мероприятие: {{ event.name }}</p>
              <p>Ваш статус в приложении: {{ user.rolein }} </p>
              <div v-if="!selectedRole">
                <p>Выберите вашу роль на мероприятии:</p>
                <select type="selectedRole" class="form-input" placeholder="Ваша роль на мероприятии" required
                  v-model="selectedRole" @change="generateQRCode">
                  <option disabled value="">Ваша роль на мероприятии</option>
                  <option>Докладчик/выступающий</option>
                  <option>Слушатель</option>
                  <option>Организатор</option>
                </select>
              </div>
              <p v-else>Ваша роль на мероприятии: {{ selectedRole }}</p>
              <p>Время генерации: {{ date }} {{ time }}</p>
              <p>Ваш QR-код для входа на мероприятие:</p>
              <div v-if="loading" class="loading-spinner">
                Загрузка...
              </div>
              <div v-if="qrCodeData && !loading && selectedRole" class="qr-code-container">
                <img :src="qrCodeData" alt="QR Code" class="qr-code-image" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="footer">
            <button type="button" class="btn-green" @click="closeModal" aria-label="Close modal">
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import QRCode from 'qrcode';

export default {
  name: 'modal',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      qrCodeData: null,
      date: null,
      time: null,
      selectedRole: '',
      loading: false,
      intervalId: null // Идентификатор интервала
    };
  },
  async created() {
    await this.getUserByUid();
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    ...mapActions({
      getUserByUid: 'user/getUserByUid'
    }),
    generateQRCode() {
      if (!this.selectedRole) return; // Не генерируем QR-код, если роль не выбрана

      this.loading = true;
      const now = new Date();
      const date = now.toLocaleDateString();
      const time = now.toLocaleTimeString();
      const data = JSON.stringify({
        useruid: this.user.uid,
        eventuid: this.event.uid,
        eventrole: this.selectedRole,
        generatedAt: now.toLocaleDateString() + " " + now.toLocaleTimeString(), // Время генерации в формате ISO
      });
      console.log(data);
      QRCode.toDataURL(data, { errorCorrectionLevel: 'H', margin: 1, scale: 10 }, (err, url) => {
        if (err) throw err;
        this.qrCodeData = url;
        this.date = date;
        this.time = time;
        this.loading = false;
      });

      // Очищаем предыдущий интервал, если он существует
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      // Устанавливаем новый интервал для обновления QR-кода каждые 10 секунд
      this.intervalId = setInterval(() => {
        this.generateQRCode();
      }, 10000);
    }
  },
  computed: {
    ...mapState('user', ['user']),
  },
  beforeDestroy() {
    // Очищаем интервал при уничтожении компонента
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
};
</script>
<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Убедитесь, что это значение выше, чем у других элементов */
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
  /* Это значение должно быть выше, чем у backdrop */
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

.loading-spinner {
  text-align: center;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code-image {
  width: 300px; /* Установите желаемый размер */
  height: 300px; /* Установите желаемый размер */
}
</style>

<style scoped>
.form-input {
    padding: 15px; /* Increased padding for better readability */
  margin: 0;
  border: none; /* Removed border for input fields */
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5; /* Set input background to light gray */
}
.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  text-align: left; /* Выравнивание текста по левому краю */
}

.btn-green {
  color: white;
  background: #3c218c;
  border: 1px solid #eeeeee;
  border-radius: 20px;
}
</style>