<template>
  <div class="content">
    <div class="home">
      <h2>Вход</h2>
      <form @submit.prevent="go_login">
        <p>
          Введите ваш логин и пароль для aFishka
        </p>
        <!-- <p>
      Если для регистрации Вы использовали учетную запись Microsoft, введите логин
      в формате xxxxxx@pspu.ru и пароль для aFishka
      <br>
      Если для регистрации Вы использовали обычную регистрацию, введите логин и
      пароль для aFishka
      </p> -->
        <input type="email" class="form-input" placeholder="Введите email" v-model="email">
        <input type="password" class="form-input" placeholder="Введите пароль" v-model="password">
        <button type="submit" class="form-btn">Войти</button>
        <span>Нет аккаунта? <router-link :to="{ path: '/register' }">Зарегистрироваться</router-link></span>
      </form>
    </div>
  </div>
  <!-- <customfooter/> -->

</template>

<script>
import { mapActions } from 'vuex';
import customfooter from "@/components/footer.vue";
// import navbarauth from "@/components/navbarauth.vue";
import MsLoginButton from "@/components/MsLoginButton.vue";
import { mapMutations } from 'vuex';

export default {
  name: 'loginpage',
  data() {
    return {
      email: '',
      password: '',
      account: undefined,
      containers: []
    }
  },
  created() {
    console.log('created in page')
    console.log('VUE_APP_SERVER:', process.env.VUE_APP_SERVER);
    this.$emitter.on(
      'login',
      async function (account) {
        this.account = account;
        console.log(this.account);
        console.log('Getting storage data');
      }.bind(this),
    ),
      this.$emitter.on('logout', () => {
        console.log('logging out');
        this.account = undefined;
      });
  },
  methods: {
    ...mapMutations(['setAccessToken']),
    ...mapActions({
      login: 'auth/login'
    }),
    go_login() {
      const formData = {
        email: this.email,
        password: this.password
      }
      this.login(formData)
    }

  },
  components: {
    MsLoginButton,
    // navbarauth,
    customfooter
  },
}
</script>

<style scoped>
.content p,
.content h2 {
  max-width: 500px;
  margin: 0 auto;
  padding: 15px;
  text-align: justify;
}

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}

form {
  height: auto;
  width: 30%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  row-gap: 20px;
  border: 1px solid #ef7d00;
  border-radius: 20px;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  form {
    width: 80%;
    height: auto;
    padding: 10px;
  }
}

.content {
  margin-top: 70px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

h2 {
  color: #000000;
  font-size: 2em;
}

.form-input {
  padding: 15px;
  border: none;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.form-btn {
  background-color: #ef7d00;
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  margin: 10px auto;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.form-btn:hover {
  background-color: #3c218c;
}

a {
  color: #3c218c;
  text-decoration: none;
}

a:hover {
  color: #ef7d00;
}
</style>