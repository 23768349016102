<template>
    <transition name="modal-fade">
      <div class="modal-backdrop" @click.self="closeModal">
        <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
          <div class="modal-header" id="modalTitle">
            <div class="header">
              Редактирование мероприятия
            </div>
          </div>
          <div class="modal-body" id="modalDescription">
            <div class="body">
              <div class="home">
                <form @submit.prevent="saveEvent()" class="event-form">
                  <label for="eventName">Название</label>
                  <input type="text" id="eventName" name="name" class="form-input" placeholder="Название" required
                    v-model="event.name">
  
                  <label for="eventDate">Дата</label>
                  <input type="date" id="eventDate" name="date" class="form-input" placeholder="Дата" required
                    v-model="event.date">
  
                  <label for="eventTimeStart">Время начала</label>
                  <input type="time" id="eventTimeStart" name="time" class="form-input" placeholder="Время начала" required
                    v-model="event.timestart">
                  <label for="eventTimeEnd">Время окончания</label>
                  <input type="time" id="eventTimeEnd" name="time" class="form-input" placeholder="Время окончания" required
                    v-model="event.timeend">
  
                  <label for="eventDescription">Описание</label>
                  <input type="text" id="eventDescription" name="description" class="form-input" placeholder="Описание"
                    required v-model="event.description">
  
                  <label for="eventImageName">Название изображения</label>
                  <input type="text" id="eventImageName" name="imageName" class="form-input"
                    placeholder="Название изображения" required v-model="event.imagename">
  
                  <label for="eventOrganizator">Организатор</label>
                  <select id="eventOrganizator" name="organizator" class="form-input" required v-model="event.organizator">
                    <option v-for="org in organizators" :key="org.uid" :value="org.uid">{{ org.fullname }}</option>
                  </select>
  
                  <label for="eventSuborganizator">Соорганизатор (-ы)</label>
                  <div v-for="(suborg, index) in event.suborganizator" :key="index" class="suborg-input">
                    <select class="form-input" v-model="event.suborganizator[index]">
                      <option v-for="org in organizators" :key="org.uid" :value="org.uid">{{ org.fullname }}</option>
                    </select>
                    <button type="button" @click="removeSuborganizator(index)" class="btn-remove">Удалить</button>
                  </div>
                  <button type="button" @click="addSuborganizator" class="btn-add">Добавить соорганизатора</button>
  
                  <label for="eventLocation">Место проведения</label>
                  <input type="text" id="eventLocation" name="location" class="form-input" placeholder="Место проведения"
                    required v-model="event.location">
  
                  <label for="eventClass">Класс мероприятия</label>
                  <select id="eventClass" name="EventClass" class="form-input" required v-model="event.eventclass">
                    <option v-for="cls in EventClasses" :key="cls.uid" :value="cls.uid">{{ cls.name }}</option>
                  </select>
  
                  <label for="eventType">Тип мероприятия</label>
                  <select id="eventType" name="EventType" class="form-input" required v-model="event.eventtype">
                    <option v-for="type in EventTypes" :key="type.uid" :value="type.uid">{{ type.name }}</option>
                  </select>
  
                  <label for="eventLevel">Уровень</label>
                  <select id="eventLevel" name="Level" class="form-input" required v-model="event.level">
                    <option v-for="lvl in Levels" :key="lvl.uid" :value="lvl.uid">{{ lvl.name }}</option>
                  </select>
  
                  <label for="eventSubType">Подтип мероприятия</label>
                  <select id="eventSubType" name="EventSubType" class="form-input" required v-model="event.eventsubtype">
                    <option v-for="subType in EventSubTypes" :key="subType.uid" :value="subType.uid">{{ subType.name }}
                    </option>
                  </select>
  
                  <label for="eventMember">Участники</label>
                  <div v-for="(member, index) in event.members" :key="index" class="member-input">
                    <select class="form-input" v-model="event.members[index]">
                      <option v-for="mem in Members" :key="mem.uid" :value="mem.uid">{{ mem.name }}</option>
                    </select>
                    <button type="button" @click="removeMember(index)" class="btn-remove">Удалить</button>
                  </div>
                  <button type="button" @click="addMember" class="btn-add">Добавить участника</button>
  
                  <label for="eventOrganizationForm">Форма организации</label>
                  <select id="eventOrganizationForm" name="OrganizationForm" class="form-input" required
                    v-model="event.organizationform">
                    <option v-for="form in OrganizationForms" :key="form.uid" :value="form.uid">{{ form.name }}</option>
                  </select>
  
                  <label for="eventResponsible">Ответственный</label>
                  <input type="text" id="eventResponsible" name="Responsible" class="form-input"
                    placeholder="Ответственный" required v-model="event.responsible">
                  <label for="eventOrganizerEmails">Email организаторов</label>
                  <div v-for="(email, index) in event.responsiblelogin" :key="index" class="email-input">
                    <input type="email" class="form-input" placeholder="Email организатора" v-model="event.responsiblelogin[index]" required>
                    <button type="button" @click="removeOrganizerEmail(index)" class="btn-remove">Удалить</button>
                  </div>
                  <button type="button" @click="addOrganizerEmail" class="btn-add">Добавить email организатора</button>
                  <p class="note">Все email, которые вы добавите, будут иметь доступ к управлению мероприятием!</p>
                  <label for="eventAdditionalLinks">Дополнительные ссылки</label>
                  <div v-for="(link, index) in event.additionallinks" :key="index" class="link-input">
                    <input type="text" class="form-input" placeholder="Ссылка" v-model="event.additionallinks[index]">
                    <button type="button" @click="removeLink(index)" class="btn-remove">Удалить</button>
                  </div>
                  <button type="button" @click="addLink" class="btn-add">Добавить ссылку</button>
                  <button type="submit" class="form-btn">Сохранить изменения</button>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="footer">
              <button type="button" class="btn-green" @click="closeModal" aria-label="Close modal">
                Закрыть
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="loading" class="loading-overlay">
        <div class="loading-spinner"></div>
        <p>Идет загрузка данных...</p>
      </div>
    </transition>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import moment from 'moment';
  
  export default {
    name: 'ModalEditEvent',
    props: {
      event: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        loading: false
      }
    },
    computed: {
      ...mapState('references', [
        'organizators',
        'EventClasses',
        'EventTypes',
        'Levels',
        'EventSubTypes',
        'Members',
        'OrganizationForms'
      ])
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      ...mapActions('references', [
        'getorganizators',
        'geteventclasses',
        'geteventtypes',
        'getlevels',
        'geteventsubtypes',
        'getmembers',
        'getorganizationforms'
      ]),
      ...mapActions('event', [
        'updateevent',
        'getallevents'
      ]),
      async saveEvent() {
        if (!this.validateForm()) {
          window.alert('Пожалуйста, заполните все обязательные поля');
          return;
        }
  
        const formattedDate = moment(this.event.date).format('YYYY-MM-DD');
        const formData = {
          uid: this.event.uid,
          name: this.event.name,
          date: formattedDate,
          timestart: this.event.timestart || 'не определено', // Добавляем проверку на null
          timeend: this.event.timeend || 'не определено', // Добавляем проверку на null
          description: this.event.description,
          imagename: this.event.imagename,
          organizator: this.event.organizator,
          suborganizator: this.event.suborganizator.filter(suborg => suborg), // Фильтруем пустые значения
          location: this.event.location,
          eventclass: this.event.eventclass,
          eventtype: this.event.eventtype,
          level: this.event.level,
          eventsubtype: this.event.eventsubtype,
          additionallinks: this.event.additionallinks.filter(link => link), // Фильтруем пустые значения
          members: this.event.members.filter(member => member), // Фильтруем пустые значения
          organizationform: this.event.organizationform,
          responsible: this.event.responsible,
          responsiblelogin: this.event.responsiblelogin.filter(email => email) // Добавляем email организаторов
        };
  
        console.log('Form Data:', formData); // Добавлено для отладки
  
        try {
          await this.updateevent(formData);
          console.log(formData);
          window.alert('Мероприятие обновлено');
          this.getallevents();
          this.closeModal();
        } catch (error) {
          console.error('Ошибка при обновлении мероприятия:', error);
        }
      },
      validateForm() {
        return this.event.name && this.event.date && this.event.timestart && this.event.timeend && this.event.description && this.event.imagename &&
          this.event.organizator && this.event.location && this.event.eventclass && this.event.eventtype &&
          this.event.level && this.event.eventsubtype && this.event.members.length > 0 && this.event.organizationform &&
          this.event.responsible && this.event.responsiblelogin.length > 0; // Добавляем проверку на наличие хотя бы одного email организатора
      },
      addLink() {
        this.event.additionallinks.push('');
      },
      removeLink(index) {
        this.event.additionallinks.splice(index, 1);
      },
      addSuborganizator() {
        this.event.suborganizator.push('');
      },
      removeSuborganizator(index) {
        this.event.suborganizator.splice(index, 1);
      },
      addMember() {
        this.event.members.push('');
      },
      removeMember(index) {
        this.event.members.splice(index, 1);
      },
      addOrganizerEmail() {
        this.event.responsiblelogin.push('');
      },
      removeOrganizerEmail(index) {
        this.event.responsiblelogin.splice(index, 1);
      }
    },
    async created() {
      this.loading = true;
      await Promise.all([
        this.getorganizators(),
        this.geteventclasses(),
        this.geteventtypes(),
        this.getlevels(),
        this.geteventsubtypes(),
        this.getmembers(),
        this.getorganizationforms()
      ]);
      this.loading = false;
    }
  }
  </script>
  
  <style scoped>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loading-spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s;
  }
  
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  
  .home {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .event-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .form-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .form-btn {
    padding: 10px;
    background-color: #3c218c;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
  }
  
  .form-btn:hover {
    background-color: #ef7d00;
  }
  
  .file-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    background-color: #f8f8f8;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
  }
  
  .file-input::-webkit-file-upload-button {
    padding: 10px;
    background-color: #3c218c;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .file-input::-webkit-file-upload-button:hover {
    background-color: #3c218c;
  }
  
  .modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Убедитесь, что это значение выше, чем у других элементов */
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
  /* Это значение должно быть выше, чем у backdrop */
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}
  .btn-green {
    color: white;
    background: #3c218c;
    border: 1px solid #eeeeee;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .btn-add,
  .btn-remove {
    padding: 10px;
    background-color: #3c218c;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 5px;
  }
  
  .btn-add:hover,
  .btn-remove:hover {
    background-color: #ef7d00;
  }
  
  /* .btn-add::after {
    content: " ссылки";
  } */
  </style>