import axios from 'axios';

const state = {
  registrations: []
};

const getters = {
  registrations: state => state.registrations
};

const mutations = {
  SET_REGISTRATIONS(state, registrations) {
    state.registrations = registrations;
  }
};

const actions = {
  async getEventRegistrationsByUserUid({ commit }, userUid) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_SERVER}/api/eventregistration/registrations/user/${userUid}`);
      if (response.data && Array.isArray(response.data)) {
        commit('SET_REGISTRATIONS', response.data);
        return response.data;
      } else {
        console.error('Некорректный ответ от сервера:', response.data);
        return [];
      }
    } catch (error) {
      console.error('Error getting event registrations by user UID:', error);
      return [];
    }
  },

  async getEventRegistrationsByEventUid({ commit }, eventuid) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_SERVER}/api/eventregistration/registrations/${eventuid}`);
      if (response.data && Array.isArray(response.data)) {
        commit('SET_REGISTRATIONS', response.data);
        return response.data;
      } else {
        console.error('Некорректный ответ от сервера:', response.data);
        return [];
      }
    } catch (error) {
      console.error('Error getting event registrations:', error);
      return [];
    }
  },

  async submitEventRegistration({ commit }, formData) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_SERVER}/api/eventregistration/registrations`, formData);
      if (response.status === 201) {
        return { success: true, message: 'Вы успешно зарегистрировались на мероприятие!' };
      } else {
        return { success: false, message: 'Ошибка при создании регистрации' };
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      return { success: false, message: 'Ошибка при отправке формы' };
    }
  },

  async getAllEventRegistrations({ commit }) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_SERVER}/api/eventregistration/registrations`);
      commit('SET_REGISTRATIONS', response.data);
    } catch (error) {
      console.error('Error getting all event registrations:', error);
    }
  },

  async getEventRegistrationById({ commit }, id) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_SERVER}/api/eventregistration/registrations/${id}`);
      commit('SET_REGISTRATIONS', [response.data]);
    } catch (error) {
      console.error('Error getting event registration by ID:', error);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};