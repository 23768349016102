<template>
  <router-view/>
  <customfooter />
</template>
<script>
import customfooter from "@/components/footer.vue";
export default {
  components: {
    customfooter,
  },
}
</script>
<style>
@font-face {
  font-family: 'Golos';
  src: url('./assets/fonts/golostext_demibold.ttf') format('truetype'); /* Путь к шрифту */
  font-weight: normal;
  font-style: normal;
}
#app {
  font-family: 'Golos', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
nav {
  padding: 30px;
}
</style>
