export default {
  namespaced: true,
  state: {
    comments: []
  },
  getters: {
    comments: state => state.comments
  },
  mutations: {
    SET_COMMENTS(state, comments) {
      state.comments = comments;
    },
    ADD_COMMENT(state, comment) {
      state.comments.push(comment);
    },
    REMOVE_COMMENT(state, uid) {
      state.comments = state.comments.filter(comment => comment.uid !== uid);
    },
    CLEAR_COMMENTS(state) {
      state.comments = [];
    }
  },
  actions: {
    async fetchCommentsForEvent({ commit }, eventUid) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/comments/${eventUid}`);
        if (!response.ok) {
          throw new Error('Failed to fetch comments');
        }
        const comments = await response.json();
        commit('SET_COMMENTS', comments);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    },
    async addComment({ commit }, { text, eventuid, useruid, userName, userSurName, userEmail }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/comments/add`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({
            text,
            eventuid,
            useruid,
            userName,
            userSurName,
            userEmail
          })
        });
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Server error:', errorData);
          throw new Error('Failed to add comment');
        }
        const newComment = await response.json();
        commit('ADD_COMMENT', newComment);
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    },
    async fetchCommentsForUser({ commit }, userUid) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/comments/foruser/${userUid}`);
        if (!response.ok) {
          throw new Error('Failed to fetch comments');
        }
        const comments = await response.json();
        commit('SET_COMMENTS', comments);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    },
    async deleteComment({ commit }, uid) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/comments/delete/${uid}`, {
          method: 'DELETE',
        });
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Server error:', errorData);
          throw new Error('Failed to delete comment');
        }
        console.log('Comment deleted with uid:', uid);
        commit('REMOVE_COMMENT', uid);
      } catch (error) {
        console.error('Error deleting comment:', error);
      }
    }
  }
};