import router from '@/router';

export default {
  name: 'attendance',
  state: {
    attendances: [],
    favoriteAttendances: []
  },
  getters: {
    attendances: state => state.attendances,
  },
  mutations: {
    SET_ATTENDANCES(state, attendances) {
      state.attendances = attendances;
    },
    ADD_ATTENDANCE(state, attendance) {
      state.attendances.push(attendance);
    }
  },
  actions: {
    async addAttendance({ commit }, { 
      useruid,
      scanuid,
      eventuid,
      eventrole
    }) {
      try {
        console.log('Sending Attendance Data:', { useruid, scanuid, eventuid, eventrole }); // Логирование данных перед отправкой
  
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/attendance/add`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({
            useruid: useruid,
            scanuid: scanuid,
            eventuid: eventuid,
            eventrole: eventrole
          })
        });
  
        if (response.ok) {
          const newAttendance = await response.json();
          commit('ADD_ATTENDANCE', newAttendance);
          console.log("Attendance added successfully");
        } else {
          console.error('Ошибка при добавлении записи о посещении:', response.statusText);
        }
      } catch (error) {
        console.error('Ошибка при добавлении записи о посещении:', error);
      }
    },
    async getAllAttendances({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/attendance/getallattendance`);
        const attendances = await response.json();
        commit('SET_ATTENDANCES', attendances);
      } catch (error) {
        console.error('Ошибка при получении записей о посещении:', error);
      }
    },
    async getAttendanceByEventUid({ commit }, eventuid) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/attendance/event/${eventuid}`);
        const attendances = await response.json();
        commit('SET_ATTENDANCES', attendances);
        return attendances; // Возвращаем данные, чтобы их можно было использовать в компоненте
      } catch (error) {
        console.error('Ошибка при получении записей о посещении по eventuid:', error);
        return []; // Возвращаем пустой массив в случае ошибки
      }
    },
    async getAttendanceByUserUid({ commit }, useruid) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/attendance/user/${useruid}`);
        const attendances = await response.json();
        commit('SET_ATTENDANCES', attendances);
        return attendances; // Возвращаем данные, чтобы их можно было использовать в компоненте
      } catch (error) {
        console.error('Ошибка при получении записей о посещении по useruid:', error);
        return []; // Возвращаем пустой массив в случае ошибки
      }
    },
  },
  namespaced: true
}