<template>
    <navbar />
    <div class="attendance-report">
      <h1>Отчет о присутствии на мероприятие "{{ eventId }}"</h1>
      <button @click="downloadExcel">Скачать в Excel</button>
      <table>
        <thead>
          <tr>
            <th>Пользователь</th>
            <th>Сканер вход</th>
            <th>Сканер выход</th>
            <th>Время входа</th>
            <th>Время выхода</th>
            <th>Роль</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="attendance in attendances" :key="attendance.id">
            <td>{{ attendance.useruid }}</td>
            <td>{{ attendance.scanuidenter }}</td>
            <td>{{ attendance.scanuidexit }}</td>
            <td>{{ attendance.scannedenter }}</td>
            <td>{{ attendance.scannedexit }}</td>
            <td>{{ attendance.eventrole }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
import navbar from "@/components/navbar.vue";
import { mapActions, mapGetters } from 'vuex';
import * as XLSX from 'xlsx';

export default {
  components: {
    navbar,
  },
  props: {
    eventName: {
      type: String,
      required: true
    },
    eventId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('attendance', ['attendances']),
  },
  methods: {
    ...mapActions('attendance', ['getAttendanceByEventUid']),
    downloadExcel() {
      const data = this.attendances.map(attendance => ({
        'Пользователь': attendance.useruid,
        'Сканер вход': attendance.scanuidenter,
        'Сканер выход': attendance.scanuidexit,
        'Время входа': attendance.scannedenter,
        'Время выхода': attendance.scannedexit,
        'Роль': attendance.eventrole,
      }));

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Присутствие');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      this.saveAsExcelFile(excelBuffer, 'attendance.xlsx');
    },
    saveAsExcelFile(buffer, fileName) {
      const data = new Blob([buffer], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  async created() {
    await this.getAttendanceByEventUid(this.eventId);
  },
};
</script>
<style scoped>
.attendance-report {
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

button {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
</style>