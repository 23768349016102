import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Emitter from 'tiny-emitter';

const app = createApp(App);

// Добавляем глобальные свойства
app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();

// Используем store и router
app.use(store).use(router);

// Монтируем приложение
app.mount('#app');

// После монтирования приложения, перенаправляем на /login
// router.push('/login');