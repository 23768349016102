<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="social-icons">

        <a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube"></i></a>
        <a href="https://vk.com/" target="_blank"><i class="fab fa-vk"></i></a>
        <a href="https://zen.yandex.ru/" target="_blank"><i class="fab fa-yandex-international"></i></a>
        <a href="https://telegram.org/" target="_blank"><i class="fab fa-telegram-plane"></i></a>
      </div>
      <div class="footer-text">
        По всем интересующим вопросам обращайтесь на почту: afishkahelper@gmail.com
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  margin-top: 600px;
  background-color: #3c218c;
  color: #fff;
  padding: 20px;
  text-align: center;
  width: 100%; /* Добавлено */
  box-sizing: border-box; /* Добавлено */
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%; /* Добавлено */
}

.social-icons a {
  color: #fff;
  margin: 0 10px;
  font-size: 24px;
}

.footer-text {
  margin-top: 10px;
}
</style>