<template>
    <transition name="modal-fade">
      <div class="modal-backdrop" @click.self="closeModal">
        <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
          <div class="modal-header" id="modalTitle">
            <div class="header">
              Ваши комментарии:
            </div>
          </div>
          <div class="modal-body" id="modalDescription">
            <div class="body">
              <div v-for="comment in localComments" :key="comment.uid" class="comment">
                <div class="comment-text">{{ comment.text }}</div>
                <div class="comment-date">{{ formatDate(comment.createdAt) }}</div>
                <button class="btn-red" @click="deleteComment(comment.uid)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="footer">
              <button type="button" class="btn-green" @click="closeModal" aria-label="Close modal">
                Закрыть
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  
  export default {
    name: 'CommentsModal',
    data() {
      return {
        localComments: []
      };
    },
    async created() {
      await this.getUserByUid();
      await this.fetchCommentsForUser(this.user.uid);
      console.log('Comments in component:', this.comments);
    },
    methods: {
  closeModal() {
    this.$emit('close');
  },
  ...mapActions('comments', ['fetchCommentsForUser', 'deleteComment']),
  formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  },
  async deleteComment(uid) {
    console.log('Deleting comment with uid:', uid);
    await this.$store.dispatch('comments/deleteComment', uid); // Исправленный вызов
    console.log('Comment deleted successfully');
  },
  ...mapActions({
    getUserByUid: 'user/getUserByUid'
  }),
},
    computed: {
      ...mapGetters('comments', ['comments']),
      ...mapState('user', ['user']),
      localComments() {
        return this.comments;
      }
    }
  };
  </script>
<style scoped>
.comment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.comment-text {
    flex-grow: 1;
}

.comment-date {
    font-size: 0.8em;
    color: #999;
    margin-right: 10px;
}

.btn-red {
    color: white;
    background: #3c218c;
    border: 1px solid #eeeeee;
    border-radius: 20px;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 80%; /* Изменено */
  max-width: 800px; /* Изменено */
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-green {
    color: white;
    background: #3c218c;
    border: 1px solid #eeeeee;
    border-radius: 20px;
}
</style>