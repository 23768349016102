import { Client } from '@microsoft/microsoft-graph-client';
export async function getUserProfile(accessToken) {
  const client = Client.init({
    authProvider: (done) => {
      done(null, accessToken);
    },
  });

  try {
    const profile = await client.api('/me').get();
    return profile;
  } catch (error) {
    throw new Error(error);
  }
} 