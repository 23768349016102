import router from '@/router';
import instance from "@/middlewares";

const checkStatuses = (status) => {
    switch (status) {
        case 400:
            window.alert('Проблема на клиенте?');
            return false;
        case 404:
            window.alert('Пользователь не найден');
            return false;
        case 414:
            window.alert('Пароль неверный');
            return false;
        case 405:
            window.alert('Пользователь с таким Email уже существует. Выполните вход.');
            return false;
        case 403:
            window.alert('Не авторизован');
            return false;
        case 500:
            window.alert('Проблема на сервере');
            return false;
        case 401:
            window.alert('Проблема с токеном');
            return false;
        default:
            return true;
    }
};

export default {
    name: 'auth',
    state: () => ({
        isAuth: false
    }),
    getters: {},
    mutations: {
        setAuth(state, isAuth) {
            state.isAuth = isAuth;
        }
    },
    actions: {
        async register({ }, { email, password, name, surname, thirdname, rolein, organization, faculty, user_group, security, country, authType }) {
            try {
                const response = await instance.post('/api/auth/signup', {
                    email,
                    password,
                    surname,
                    name,
                    thirdname,
                    rolein,
                    organization,
                    faculty,
                    user_group,
                    security,
                    country,
                    authType
                });

                if (!checkStatuses(response.status)) return;

                window.alert('Вы успешно зарегистрировались!');
                router.push('/');
            } catch (error) {
                console.error('Registration error:', error.response ? error.response.data : error.message);
            }
        },
        async login({ commit }, { email, password }) {
            try {
                const response = await instance.post('/api/auth/signin', {
                    email,
                    password
                });

                if (!checkStatuses(response.status)) return;

                const result = response.data;
                commit('setAuth', true);
                localStorage.setItem('accessToken', result.accessToken);
                localStorage.setItem('refreshToken', result.refreshToken);
                localStorage.setItem('uid', result.uid);
                router.push('/EventsView');
            } catch (error) {
                console.error('Login error:', error.response ? error.response.data : error.message);
            }
        },
        async changeAccess({ }) {
            try {
                const response = await instance.post('/api/auth/changeAccess', {}, {
                    headers: {
                        'x-refresh-token': localStorage.getItem('refreshToken')
                    }
                });

                if (!checkStatuses(response.status)) return;

                localStorage.setItem('accessToken', response.data.accessToken);
                localStorage.setItem('refreshToken', response.data.refreshToken);
            } catch (error) {
                console.error('Change access error:', error.response ? error.response.data : error.message);
            }
        }
    },
    namespaced: true
};