<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" @click.self="closeModal">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription"
                @click.stop>
                <div class="modal-header" id="modalTitle">
                    <div class="header">
                        Добавление волонтера
                    </div>
                </div>
                <div class="modal-body" id="modalDescription">
                    <div class="body">
                        <div class="home">
                            <form @submit.prevent="addVolonter" class="event-form">
                                <input type="text" name="emailadress" class="form-input"
                                    placeholder="Email волонтера в aFishka" required v-model="emailadress">
                                <button type="submit" class="form-btn">Добавить</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="footer">
                        <button type="button" class="btn-green" @click="closeModal" aria-label="Close modal">
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions } from 'vuex';
import * as XLSX from 'xlsx';
import axios from 'axios';
export default {
    name: 'ModalAddEvent',
    data() {
        return {
            emailadress: '',
        }
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        addVolonter() {
            axios.put(`${process.env.VUE_APP_SERVER}/api/users/addVolonter/${this.emailadress}`)
                .then(response => {
                    console.log(response.data.message);
                    window.alert('Волонтер добавлен. Теперь ему доступны инструменты волонтера')
                })
                .catch(error => {
                    console.error(error);
                    window.alert('Ошибка')
                });
        },
    }
}
</script>
<style scoped>
.home {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.event-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
}

.form-btn {
    padding: 10px;
    background-color: #3c218c;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.form-btn:hover {
    background-color: #ef7d00;
}

.file-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    background-color: #f8f8f8;
    cursor: pointer;
}

.file-input::-webkit-file-upload-button {
    padding: 10px;
    background-color: #3c218c;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.file-input::-webkit-file-upload-button:hover {
    background-color: #3c218c;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Убедитесь, что это значение выше, чем у других элементов */
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
  /* Это значение должно быть выше, чем у backdrop */
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}
.btn-green {
    color: white;
    background: #3c218c;
    border: 1px solid #eeeeee;
    border-radius: 20px;
}
</style>