<template>
  <nav class="main-nav">
    <div class="left-part">
      <img src="../assets/NavBarLogo.png" alt="Logo" class="logo circle" @click="goToEventsView">
    </div>
    <div class="center-part" v-if="$route.path === '/EventsView'">
      <h2>Мероприятия</h2>
    </div>
    <div class="center-part" v-if="$route.path === '/favorite'">
      <h2>Избранное</h2>
    </div>
    <div class="center-part" v-if="$route.path === '/about'">
      <h2>Личный кабинет</h2>
    </div>
    <div class="right-part">
      <button class="burger-button" @click="toggleMenu">
        <i class="fas fa-bars"></i>
      </button>
      <div class="dropdown-menu" :class="{ 'show-menu': isMenuOpen }">
        <router-link to="/EventsView" class="dropdown-button" v-bind:class="{ 'active-button': $route.path === '/EventsView' }">
          <i class="fas fa-calendar-alt"></i>
          <span class="button-label">Мероприятия</span>
        </router-link>
        <router-link to="/favorite" class="dropdown-button" v-bind:class="{ 'active-button': $route.path === '/favorite' }">
          <i class="fas fa-heart"></i>
          <span class="button-label">Избранное</span>
        </router-link>
        <router-link to="/about" class="dropdown-button" v-bind:class="{ 'active-button': $route.path === '/about' }">
          <i class="fas fa-user"></i>
          <span class="button-label">Личный кабинет</span>
        </router-link>
        <button class="dropdown-button" @click="logout">
          <i class="fas fa-sign-out-alt"></i>
          <span class="button-label">Выйти</span>
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import router from '@/router';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      isMenuOpen: false
    };
  },
  methods: {
    goToEventsView() {
      this.$router.push('/EventsView');
    },
    ...mapActions('user', ['logoutAction']),
    async logout() {
      try {
        await this.logoutAction();
        localStorage.clear();
        this.$store.commit('user/setUser', null);
        this.$router.push('/');
        window.alert('Вы вышли из аккаунта');
      } catch (error) {
        console.log(error);
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
}
</script>

<style scoped>
.main-nav {
  background-color: #3c218c;
  overflow: hidden;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}

.circle {
  border-radius: 50%;
  width: 100%;
  height: auto;
}

.left-part {
  display: flex;
  align-items: center;
  max-width: 10%;
  max-height: auto;
  padding-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.5s ease;
  margin-left: 30px;
  border-radius: 20px;
  box-sizing: border-box;
}

.logo {
  max-width: 50%;
  max-height: auto;
  background-color: aliceblue;
  cursor: pointer;
  box-sizing: border-box;
}

.center-part {
  display: flex;
  align-items: center;
  position: relative;
  width: 30%;
  justify-content: center;
  box-sizing: border-box;
}

.right-part {
  display: flex;
  /* padding-right: 30px;
  margin-right: 30px; */
  box-sizing: border-box;
}

.burger-button {
  display: none;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 20px;
}

.dropdown-menu {
  display: flex;
  align-items: center;
}

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  border-style: hidden;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 0 0 1px white;
  width: 30px;
  height: 30px;
  background-color: #3c218c;
  box-sizing: border-box;
}

.dropdown-button:hover {
  box-shadow: 0 0 0 1px #ef7d00;
  color: #ef7d00;
  background-color: #3c218c;
}

.active-button {
  color: #ef7d00;
  box-shadow: 0 0 0 1px #ef7d00;
}

h2 {
  color: aliceblue;
}

@media screen and (max-width: 768px) {
  .main-nav {
    padding: 0;
    justify-content: space-between;
    width: 100%;
  }
  .right-part {
  display: flex;
  box-sizing: border-box;
}
  .left-part {
    display: none;
  }

  .center-part {
    width: auto;
    margin-left: 20px;
  }

  .burger-button {
    display: flex;
    margin-right: 20px;
  }

  .dropdown-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #3c218c;
    width: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-menu.show-menu {
    display: flex;
  }

  .dropdown-button {
    margin: 10px 0;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    justify-content: flex-start;
    padding-left: 20px;
  }

  .button-label {
    margin-left: 10px;
    color: white;
  }
}

@media screen and (min-width: 769px) {
  .button-label {
    display: none;
  }
}
</style>