<template>
    <!-- <navbarauth /> -->
    <div classs="content">
    <h2>Регистрация</h2>
    <div class="home">
        
        <form @submit.prevent="go_register()">
            <input type="email" class="form-input" placeholder="Введите email" required minlength="5" maxlength="40"
                v-model="email">
            <input type="password" class="form-input" placeholder="Введите пароль" required minlength="8" maxlength="40"
                v-model="password">
            <input type="password" class="form-input" placeholder="Подтвердите пароль" required minlength="8"
                maxlength="40" v-model="confirmPassword">
            <input type="surname" class="form-input" placeholder="Введите фамилию" required v-model="surname">
            <input type="name" class="form-input" placeholder="Введите имя" required v-model="name">
            <input type="thirdname" class="form-input" placeholder="Введите отчество" required v-model="thirdname">
            <select type="rolein" class="form-input" placeholder="Роль (ученик, студент, преподаватель, гость)" required
                v-model="rolein">
                <option value="" disabled selected>Роль в ПГГПУ</option>
                <option value="Студент ПГГПУ">Студент ПГГПУ</option>
                <option value="Преподаватель ПГГПУ">Преподаватель ПГГПУ</option>
                <option value="Студент">Студент</option>
                <option value="Преподаватель">Преподаватель</option>
                <option value="Ученик">Ученик</option>
                <option value="Учитель">Учитель</option>
                <option value="Гость">Гость</option>
            </select>
            <input type="organization" class="form-input" placeholder="Организация / учебное заведение (аббревиатура)"
                v-model="organization" @input="updateFields">
            <!-- <select type ="faculty" v-if="study === 'ПГГПУ'|| 'пггпу'" v-model="faculty" class="form-input"> -->
            <select type="faculty" v-if="organization === 'ПГГПУ' || this.organization === 'пггпу'" v-model="faculty" class="form-input">
                <option value="" disabled selected>Факультет (если есть)</option>
                <option value="МФ">МФ</option>
                <option value="ФФ">ФФ</option>
                <option value="ФФК">ФФК</option>
                <option value="ПиМНО">ПиМНО</option>
                <option value="ППД">ППД</option>
                <option value="ЕНФ">ЕНФ</option>
                <option value="ИнЯз">ИнЯз</option>
                <option value="ФизФ">ФизФ</option>
                <option value="ИстФ">ИстФ</option>
                <option value="МузФ">МузФ</option>
                <option value="ПсФ">ПсФ</option>
                <option value="ИНЭК">ИНЭК</option>
                <option value="ПиСПО">ПиСПО</option>
            </select>
            <input v-else type="faculty" class="form-input" placeholder="Факультет (если есть)" v-model="faculty">

            <input type="group" class="form-input" placeholder="Группа или класс (если есть)" v-model="user_group">

            <button type="submit" class="form-btn">Регистрация</button>
            <span>Уже есть аккаунт? <router-link :to="{ path: '/' }">Войти</router-link></span>
        </form>
        <!-- <MsLoginButton>Войти через Microsoft</MsLoginButton> -->
    </div>
</div>
</template>
<script>
import { mapActions } from 'vuex';
import MsLoginButton from "@/components/MsLoginButton.vue";
// import navbarauth from "@/components/navbarauth.vue";
export default {
    name: 'register',

    data() {
        return {
            email: '',
            password: '',
            surname: '',
            name: '',
            thirdname: '',
            authType:'',
            rolein: '',
            organization: '',
            faculty: '',
            user_group: '',
            security:'',
            country: '',
        }
    },
    methods: {

        ...mapActions({
            register: 'auth/register'
        }),
        go_register() {
            if (this.password !== this.confirmPassword) {
                alert('Пароли не совпадают');
                return;
            }
            const formData = {
                email: this.email,
                password: this.password,
                surname: this.surname,
                name: this.name,
                thirdname: this.thirdname,
                rolein: this.rolein,
                organization: this.organization || "Не указано",
                faculty: this.faculty || "Не указано",
                user_group: this.user_group || "Не указано",
                authType: "jwt",
                security: "defaultuser",
                country:'Россия'
            }
            console.log(formData);
            this.register(formData)
        },
        updateFields() {
            if (this.study !== 'ПГГПУ' || 'пггпу') {
                this.faculty = ''; // Clear the faculty field if study is not 'ПГГПУ'
                this.group = '';   // Clear the group field if study is not 'ПГГПУ'
            }
        }


    },
    components: {
        MsLoginButton,
        // navbarauth
    },

}
</script>
<style scoped>
.home {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100svh; */
    /* justify-content: center; */
    align-items: center;
}

form {
    display: flex;
    padding: 20px;
    flex-direction: column;
    width: 400px;
    row-gap: 20px;
    border: 1px solid #ef7d00; /* Added border with orange color */
    border-radius: 20px;
    background-color: #fff; /* Set form background to white */
}

@media screen and (max-width: 768px) {
  form {
    padding: 10px;  /* Reduce padding for mobile */
    width: 80%;   /* Remove fixed width, take full width on mobile */
  }
}

.content {
  margin-top: 70px;
  text-align: center;
}

h2 {
  color: #000000;
  font-size: 2em;
}

p {
  color: #000000;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-input {
    padding: 15px; /* Increased padding for better readability */
  margin: 0;
  border: none; /* Removed border for input fields */
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5; /* Set input background to light gray */
}

.form-btn {
    background-color: #ef7d00; /* Button background to orange */
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  margin: 10px auto; /* Center the button horizontally */
  border-radius: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.form-btn:hover {
  background-color: #3c218c;
}

a {
  color: #3c218c;
  text-decoration: none;
}

a:hover {
  color: #ef7d00;
}
</style>